import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3270a6", // Sostituisci con il tuo colore primario desiderato
    },
    background: {
      paper: "#f4f4f4",
    },
  },
});

export default theme;
