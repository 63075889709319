/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Typography } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import "./ServicesScreen.scss";
import serviceImage from "../../assets/images/services.jpg";
import { Link } from "react-router-dom";
import emailJs from "@emailjs/browser";

const ServicesScreen: React.FC = () => {
  const openGoogleMaps = (streetAddress: string) => {
    // Crea l'URL di Google Maps con l'indirizzo
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      streetAddress
    )}`;

    // Apri una nuova finestra o scheda del browser
    window.open(googleMapsUrl, "_blank");
  };

  const sendFeedbackToDave = async () => {
    try {
      await emailJs.send(
        "service_ojekt9l",
        "template_bppwefm",
        {
          message: "Hanno premuto Prenota una visita",
        },
        process.env.REACT_APP_SERVICE_ID
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="services-container">
      <SectionTitle title="Servizi" />

      <div className="services-body">
        <div className="section-body">
          <div className="section-p">
            <div>
              <Typography variant="subtitle1" className="paragraph">
                Presso l'ambulatorio privato in{" "}
                <Link
                  onClick={() =>
                    openGoogleMaps("Via Crespi 6, Bologna, Italia")
                  }
                  to="#"
                >
                  via G. Crespi 6
                </Link>
                , Bologna. Il <b>Martedì, Giovedì e Venerdì pomeriggio</b>.
                Chiamando al numero <b>{process.env.REACT_APP_PHONE_NUMBER}</b>{" "}
                oppure prenotando una visita dal sito:
              </Typography>
              <div style={{ display: "flex" }}>
                <a
                  className="call-button"
                  href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                  style={{ width: "75%" }}
                >
                  <Button
                    className="request-button"
                    variant="outlined"
                    style={{ width: "75%" }}
                  >
                    Chiamare per appuntamento
                  </Button>
                </a>
                <a
                  id="idw-link-3eZJof"
                  className="idw-top-main"
                  href="https://www.idoctors.it/medico/20214/0?widget=3eZJof"
                  target="_blank"
                  rel="noreferrer"
                  style={{ width: "75%" }}
                >
                  <Button
                    className="request-button"
                    variant="outlined"
                    style={{ width: "75%" }}
                    onClick={() => {
                      sendFeedbackToDave();
                    }}
                  >
                    Prenota una visita
                  </Button>
                </a>
              </div>
            </div>
            <div>
              <Typography variant="subtitle1" className="paragraph">
                Presso Poliambulatorio Giardini Margherita, via{" "}
                <a
                  href="#"
                  onClick={() =>
                    openGoogleMaps(
                      "Poliambulatorio Giardini Margherita Via S. Stefano 103, Bologna, Italia"
                    )
                  }
                >
                  S. Stefano 103
                </a>
                , Bologna, il <b>Lunedi pomeriggio</b>. Chiamando al numero{" "}
                <b>{process.env.REACT_APP_MEDICAL_CENTER_NUMBER}</b>
              </Typography>
              <a
                className="call-button"
                href={`tel:${process.env.REACT_APP_MEDICAL_CENTER_NUMBER}`}
              >
                <Button className="request-button" variant="outlined">
                  Chiamare per appuntamento
                </Button>
              </a>
            </div>
            <div>
              <Typography variant="subtitle1" className="paragraph">
                Presso Casa di cura Eugenio Gruppioni, via{" "}
                <a
                  href="#"
                  onClick={() =>
                    openGoogleMaps("Ospedale Privato Eugenio Gruppioni")
                  }
                >
                  Via Zena, 117 (loc. Farneto)
                </a>
                , Bologna, il <b>Mercoledi mattina</b>. Chiamando al numero{" "}
                <b>{process.env.REACT_APP_MEDICAL_CASA_DI_CURA}</b>
              </Typography>
              <a
                className="call-button"
                href={`tel:${process.env.REACT_APP_MEDICAL_CASA_DI_CURA}`}
              >
                <Button className="request-button" variant="outlined">
                  Chiamare per appuntamento
                </Button>
              </a>
            </div>
          </div>
          <div className="section-image">
            <img width="100%" height="auto" src={serviceImage} alt="service" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesScreen;
