import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { Typography } from "@mui/material";

import "./ExpertiesScreen.scss";

const ExpertiesScreen: React.FC = () => {
  return (
    <div className="experties-container">
      <SectionTitle title="Patologie trattate" />
      <div className="experties-body">
        <div className="section-body">
          <div className="introduction">
            <Typography variant="subtitle1">
              Sono il Dott. Gilberto Laffi, e mi dedico appassionatamente alla
              gestione completa del diabete, offrendo supporto per una vasta
              gamma di condizioni. Dal <b>diabete di tipo 1 e 2 </b>al
              <b> diabete gestazionale </b> e alle
              <b> complicanze cardiovascolari</b>, il mio obiettivo è fornire
              cure avanzate e personalizzate. Metto un forte accento
              sull'educazione alimentare e sui principi nutrizionali
              fondamentali per garantire un approccio completo alla gestione del
              diabete. Sono qui per guidarvi in ogni passo del vostro percorso
              verso il benessere. In particolare questo è l'elenco delle
              patologie trattate:
            </Typography>
          </div>
          <div className="pathologies-list">
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Diabete Mellito di tipo 1
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Diabete Mellito di tipo 2
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Diabete secondario a trattamento con farmaci o a
                  pancreasectomia
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Gestanti con comparsa di alterazione della glicemia o
                  diabetiche gravide
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Diabete con complicanze croniche micro e/o complicanze
                  cardiovascolari
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Sovrappeso, Obesità, Colesterolo e trigliceridi elevati,
                  Ipertensione arteriosa nei Diabetici
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Condizioni predisponenti al diabete mellito o intolleranza al
                  glucosio
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Diabete di nuova diagnosi o predisposizione alla comparsa del
                  Diabete
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Nuove terapie per la cura del Diabete
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Gestione dei sensori in continuo della glicemia (CGM, Holter
                  Gliemico)
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Gestione di pazienti con microinfusore di insulina
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">Sindrome metabolica</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Educazione alimentare e principi di nutrizione nella cura del
                  Diabete
                </Typography>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertiesScreen;
