import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomepageScreen from "../pages/HomepageScreen/HomepageScreen";
import LayoutContainer from "../layout/LayoutContainer";
import { PATHS } from "../constants";
import OverviewScreen from "../pages/OverviewScreen/OverviewScreen";
import ServicesScreen from "../pages/ServicesScreen/ServicesScreen";
import ContactsScreen from "../pages/ContactsScreen/ContactsScreen";
import ExpertiesScreen from "../pages/ExpertiesScreen/ExpertiesScreen";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={PATHS.HOME}
          element={
            <LayoutContainer>
              <HomepageScreen />
            </LayoutContainer>
          }
        />
        <Route
          path={PATHS.OVERVIEW}
          element={
            <LayoutContainer>
              <OverviewScreen />
            </LayoutContainer>
          }
        />
        <Route
          path={PATHS.EXPERTIES}
          element={
            <LayoutContainer>
              <ExpertiesScreen />
            </LayoutContainer>
          }
        />
        <Route
          path={PATHS.SERVICES}
          element={
            <LayoutContainer>
              <ServicesScreen />
            </LayoutContainer>
          }
        />
        <Route
          path={PATHS.CONTACTS}
          element={
            <LayoutContainer>
              <ContactsScreen />
            </LayoutContainer>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
