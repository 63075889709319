import { Button, Typography, useTheme } from "@mui/material";
import "./OverviewScreen.scss";
import imageGil from "../../assets/images/OverviewScreen.jpg";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
// @ts-ignore
import CV_pdf from "../../assets/pdf/Gil_CV.pdf";

const OverviewScreen: React.FC = () => {
  const theme = useTheme();

  return (
    <div className="overview-container">
      <SectionTitle title="Chi sono" />
      <div className="overview-body">
        <div className="section-body">
          <div className="section-p">
            <Typography variant="h4" color={theme.palette.primary.main}>
              Dott. Gilberto Laffi
            </Typography>
            <Typography variant="subtitle1" className="paragraph">
              Sono un Diabetologo che lavora a Bologna, con una formazione
              approfondita e una dedizione alla cura dei pazienti diabetici. Ho
              conseguito la mia laurea presso l’Università degli Studi di
              Bologna e ho proseguito la mia formazione con la specializzazione
              in Diabetologia e Malattie del Ricambio presso l’Università di
              Parma. Ho partecipato e tenuto corsi avanzati nella diabetologia
              regionale e Nazionale.
            </Typography>

            <Typography variant="subtitle1" className="paragraph">
              Sono stato Presidente dell’Associazione Medici Diabetologi
              dell’Emilia Romagna ed ho lavorato prima all’Ospedale Maggiore di
              Bologna e negli ultimi 22 anni alla Diabetologia del Policlinico
              S.Orsola-Malpighi sempre di Bologna* La mia missione è quella di
              migliorare la qualità di vita dei pazienti affetti da diabete
              attraverso cure personalizzate e soluzioni basate sulle ultime
              ricerche scientifiche.
            </Typography>

            <a href={CV_pdf} rel="noopener noreferrer" target="_blank">
              <Button
                variant="outlined"
                className="button-readme"
                style={{ width: "50%" }}
              >
                Leggi il mio Curriculum
              </Button>
            </a>
          </div>
          <div className="section-image">
            <img width="100%" height="auto" src={imageGil} alt="overview" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewScreen;
