import {
  Alert,
  AlertColor,
  Button,
  Divider,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import "./ContactsScreen.scss";
import { useState } from "react";
import emailJs from "@emailjs/browser";

const ContactsScreen: React.FC = () => {
  const theme = useTheme();

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);

  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    severity: AlertColor | undefined;
    text?: string;
  }>({
    open: false,
    severity: undefined,
    text: "",
  });

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await emailJs.sendForm(
        "service_8isdack",
        "template_bppwefm",
        e.target,
        process.env.REACT_APP_SERVICE_ID
      );

      setFormData(initialState);

      setSnackbarState({
        open: true,
        severity: "success",
        text: "Inviato con successo",
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        text: "Errore nell'invio dati, riprova",
      });
    }
  };

  return (
    <>
      <div className="contacts-container">
        <SectionTitle title="Contatti" />

        <div className="contacts-card">
          <Typography variant="h3" color={theme.palette.primary.main}>
            Inviami un messaggio
          </Typography>
          <Divider
            variant="middle"
            sx={{
              alignSelf: "center",
              width: "10%",
              backgroundColor: theme.palette.primary.main,
              height: 2,
              marginBottom: 2,
              marginTop: 2,
            }}
          />
          <form onSubmit={(e) => handleSubmit(e)} className="contacts-form">
            <TextField
              label="Nome"
              variant="outlined"
              margin="normal"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 30 }}
            />
            <TextField
              label="Cognome"
              variant="outlined"
              margin="normal"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 30 }}
            />
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 40 }}
            />
            <TextField
              label="Telefono"
              variant="outlined"
              margin="normal"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 15 }}
            />
            <TextField
              label="Messaggio"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 400 }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: "50%", marginTop: 16 }}
            >
              Invia
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbarState({ open: false, severity: undefined });
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarState({ open: false, severity: undefined });
          }}
          severity={snackbarState.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarState.text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactsScreen;
