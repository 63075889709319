import { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, Divider, Link } from "@mui/material";
import "./Header.scss";
import { NavBarLink } from "../../types/Layout";
import Logo from "../../assets/images/logo-color.png";
import { useLocation } from "react-router-dom";
import emailJs from "@emailjs/browser";

type HeaderProps = {
  navBarLinks: NavBarLink[];
};

const Header: React.FC<HeaderProps> = ({ navBarLinks }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://www.idoctors.it/widget.js/key/3eZJof/id/20214/type/link";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const sendFeedbackToDave = async () => {
    try {
      await emailJs.send(
        "service_ojekt9l",
        "template_bppwefm",
        {
          message: "Hanno premuto Prenota una visita",
        },
        process.env.REACT_APP_SERVICE_ID
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="headerContainer">
      <a href="/">
        <img width={120} src={Logo} alt="logo" />
      </a>
      <div className="header-links">
        <IconButton
          className="drawer-filter-button"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          style={{ display: "none" }}
        >
          <MenuIcon key={"menu"} />
        </IconButton>

        {/* Drawer for small screens */}
        <Drawer
          className="custom-drawer"
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          {navBarLinks.map((item, index) => (
            <Box
              key={item.title + index + "box"}
              sx={{
                width: 250,
                display: "flex",
                flexDirection: "column",
              }}
              role="presentation"
            >
              <Link
                key={item.title + index}
                href={item.route}
                variant="h6"
                underline={
                  location.pathname === item.route ? "always" : "hover"
                }
                sx={{
                  padding: 2,
                }}
              >
                {item.title}
              </Link>
              <Divider />
            </Box>
          ))}
          <Link
            key={"book"}
            href="https://www.idoctors.it/medico/20214/0?widget=3eZJof"
            variant="h6"
            underline="hover"
            sx={{
              padding: 2,
            }}
            onClick={() => {
              sendFeedbackToDave();
            }}
          >
            Prenota una visita
          </Link>
        </Drawer>

        {/* Navigation links for larger screens */}
        <div className="desktop-links">
          {navBarLinks.map((item) => (
            <Link
              key={item.title}
              href={item.route}
              variant="h6"
              underline={location.pathname === item.route ? "always" : "hover"}
              className={
                location.pathname === item.route ? "active-link" : "link-mui"
              }
            >
              {item.title}
            </Link>
          ))}
          <div className="book-button-div">
            <a
              id="idw-link-3eZJof"
              className="idw-top-main"
              href="https://www.idoctors.it/medico/20214/0"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className="request-button"
                variant="contained"
                onClick={() => sendFeedbackToDave()}
              >
                Prenota una visita
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
