import { PropsWithChildren } from "react";
import Header from "../components/Header/Header";
import { NavBarLink } from "../types/Layout";
import { PATHS } from "../constants";
import Footer from "../components/Footer/Footer";

type LayoutContainerProps = {};

const LayoutContainer: React.FC<PropsWithChildren<LayoutContainerProps>> = ({
  children,
}) => {
  const linkList: NavBarLink[] = [
    { title: "Home", route: PATHS.HOME },
    { title: "Chi Sono", route: PATHS.OVERVIEW },
    { title: "Patologie trattate", route: PATHS.EXPERTIES },
    { title: "Servizi", route: PATHS.SERVICES },
    { title: "Contatti", route: PATHS.CONTACTS },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Header navBarLinks={linkList} />
      <div style={{ flexGrow: 1 }}>{children}</div>
      <Footer />
    </div>
  );
};

export default LayoutContainer;
