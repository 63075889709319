import { Typography, useTheme } from "@mui/material";

type SectionTitleProps = {
  title: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => {
  const theme = useTheme();

  return (
    <div
      className="title"
      style={{
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        paddingRight: 50,
        paddingLeft: 50,
        marginBottom: 32,
        width: "100%",
        height: 100,
        color: "white",
      }}
    >
      <Typography variant="h4">{title}</Typography>
    </div>
  );
};

export default SectionTitle;
