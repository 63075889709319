import { Link, Typography, useTheme } from "@mui/material";
import "./Footer.scss";

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <footer
      className="footer-container"
      style={{ backgroundColor: theme.palette.background.paper }}
    >
      <div className="footer-info">
        <Typography>Dott. Gilberto Laffi</Typography>
        <Typography>Via Giuseppe Crespi 6, Bologna, 40129</Typography>
        <Typography>
          Telefono:{" "}
          <a href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}>
            {process.env.REACT_APP_PHONE_NUMBER}
          </a>
        </Typography>
      </div>
      <div className="footer-links">
        <Link
          href="https://www.iubenda.com/privacy-policy/35451184"
          variant="h6"
          underline="hover"
        >
          <Typography style={{ marginRight: 16 }}>Privacy Policy</Typography>
        </Link>
        <Link
          href="https://www.iubenda.com/privacy-policy/35451184/cookie-policy"
          variant="h6"
          underline="hover"
        >
          <Typography>Cookie Policy</Typography>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
